<template>
  <div class="page">
    <h2 class="main-title">{{title}}</h2>
    <div class="responsive">
      <v-skeleton-loader
        v-if="!getСatalog.length"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table table-white table-search" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              {{header.text}}
              <div class="search">
                <input type="text" v-model="header.input" @input="setSearch({column:header.value, string:header.input})">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="table in getСatalog"
            :key="table.id"
          >
            <td class="text-left">
              <a
                :href="table.address">
                {{table.name}}
              </a>
            </td>
            <td class="text-left">
              <a
                :href="table.address">
                {{table.address}}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Manual',
    data () {
      return {
        load: false,
        title: 'Справочники',
        headers: [
          {text: 'Название справочника', value: 'name', input: '', search: true },
          {text: 'Ссылка на источник справочника', value: 'address', input: '', search: true },
        ],
      }
    },
    computed : {
      ...mapGetters({
          getСatalog: 'catalog/getСatalog',
      }),
    },
    methods: {
      ...mapActions({
        catalog: 'catalog/catalog',
      }),
      ...mapMutations({
        setSearch: 'catalog/setSearch'
      }),
    },
    mounted() {
      this.catalog()
    }

  }
</script>
